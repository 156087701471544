.container {
  display: grid;
  grid-template-areas:
    "sidebar header"
    "sidebar main";
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  grid-template-columns: 320px 1fr;
  grid-template-rows: 130px 1fr;
  height: 100vh;
}

.d-container {
  display: grid;
  grid-template-areas: "sidebar main";
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  grid-template-columns: 320px 1fr;
  height: 100vh;
}

.footer {
  display: none;
}

.header-mobile {
  display: none;
}
.growth, .lastPay {
  height: 100%;
}

@media screen and (max-width: 480px) {
  .container {
    display: grid;
    grid-template-areas:
      "header"
      "main"
      "footer";
    grid-template-rows: 90px 1fr 90px;
    grid-template-columns: 1fr;
    height: 100vh;
    overflow-y: scroll;
  }
  .dashboard-container {
    margin-bottom: 90px;
  }
  .transactions-container {
    margin-bottom: 90px;
  }
  .footer {
    grid-area: footer;
    display: block;
    box-shadow: -4px -4px 8px 0 rgba(224, 224, 255, 0.04),
      0 1px 1px 0 rgba(9, 14, 20, 0.4);
    background-color: #272a2e;
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    font-size: 18px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 55;
  }
  .footer img {
    width: 32px;
  }

  .header-mobile {
    grid-area: header;
    padding: 32px;
    display: block;
  }

  .header-mobile-heading {
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
    text-transform: capitalize;
  }

  .sidebar {
    display: none;
  }

  .dashboard-container {
    grid-area: main;
    display: grid;
    grid-template-areas: "content";
    grid-template-columns: 1fr;
    color: #fff;
  }

  .content {
    grid-template-areas:
      "balance"
      "send"
      "help"
      "apps";
    grid-template-rows: auto;
    grid-template-columns: 100%;
    grid-gap: 0px;
    margin: 0px;
    padding: 0px;
  }

  .right-sidebar {
    display: none;
  }

  .header {
    display: none;
  }
  .d-container {
    display: block;
  }
  .spendable {
    font-size: 14px;
  }
  .growth, .lastPay {
    height: 90%;
  }
}
.footerIcons {
  width: 24px;
}

