*, ::after, ::before {
  box-sizing: border-box;
}
.sidebar {
  grid-area: sidebar;
  border-radius: 24px;
  box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4),
    -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  position: fixed;
  max-width: 280px;
  margin: 0 auto;
  width: 100%;
  left: 20px;
  top: 20px;
  bottom: 20px;
  overflow-y: auto;
  z-index: 555;
}

.logo {
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  letter-spacing: 6px;
  height: 80px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.network {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  letter-spacing: 6px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu {
  display: flex;
  height: calc( 100% - 80px);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.items {
  font-size: 16px;
  padding: 0 25px;
  margin: 0;
  width: 100%;
}

.logout {
  font-size: 24px;
}
.selectedItemDiv,
.itemDiv,
.logoutDiv {
  display: grid;
  grid-template-columns: 35px auto;
  padding: 12px 14px;
  margin-bottom: 12px;
  border-radius: 12px;
  color: #777987;
  cursor: pointer;
  -moz-transition: .2s linear;
	-webkit-transition: .2s ease-out;
  transition: .2s linear;
  align-items: center;
}
.logoutDiv:hover,
.selectedItemDiv:hover,
.itemDiv:hover,
.selectedItemDiv {
  background-color: #3a7df1;
  color: #fff;
}
.menuItemIcon {
  max-width: 20px;
  max-height: 20px;
  color: #777987;
}

@media screen and (max-width: 1200px){
  .d-container {
    grid-template-columns: 200px 1fr !important;
  }
  .sidebar {
    max-width: 180px;
    left: 5px;
  }
  .items {
    padding: 0 5px;
  }
  .right-sidebar {
    display: none;
  }
}

@media screen and (max-width: 1400px){
  .items {
    padding: 0 5px;
  }
  
}

@media screen and (max-height: 700px){
  .new-logo {
    display: none;
  }
}

.new-logo {
  position: absolute;
  bottom: 100px;
  width: 30%;
  left: 88px;
  max-width: 150px;
}

@media screen and (max-width: 1400px){
  .new-logo {
    left: 57px;
  }
}



