.dashboardHeader {
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    padding: 15px 3px 15px 3px;
}
.dashboardToggleBtn {
    border-radius: 12px;
    box-shadow: inset 8px 8px 40px 0 rgba(9, 14, 20, 0.4),
      inset -4px -4px 8px 0 rgba(224, 224, 255, 0.04),
      inset 0 1px 1px 0 rgba(9, 14, 20, 0.4);
    background-image: linear-gradient(to bottom, #090e14, #222529 58%, #2d2f33);
    height: 33px;
    width: 30%;
    display: grid;
    grid-template-columns: 50% 50%;
}
.balanceMobile {
    border-radius: 10px;
    box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4),
      -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4),
      inset 0 -2px 1px 0 rgba(9, 14, 20, 0.8);
    background-image: linear-gradient(to bottom, #383a3d, #2d2f33);
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    cursor: pointer;
    outline: 0;
    text-align: center;
    align-items: center;
    padding-top: 6px;
}
.balanceMobileActive {
    border-radius: 12px;
    box-shadow: inset 8px 8px 40px 0 rgba(9, 14, 20, 0.4),
        inset -4px -4px 8px 0 rgba(224, 224, 255, 0.04),
        inset 0 1px 1px 0 rgba(9, 14, 20, 0.4);
    background-image: linear-gradient(to bottom, #090e14, #222529 58%, #2d2f33);
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    cursor: pointer;
    outline: 0;
    text-align: center;
    align-items: center;
    padding-top: 6px;
}
.mobileHeaderIcon {
    height: 18px;
}
  