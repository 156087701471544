.help-container {
  grid-area: main;
  display: grid;
  grid-template-areas: "helpContent faq";
  grid-template-columns: 1fr 2fr;
  color: #fff;
  align-items: flex-start;
}
.dropdown-specs {
  box-shadow: -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  flex-wrap: wrap;
  display: none;
}
.masternodeDownArrow {
  width: 10px;
  transform: rotate(90deg);
  opacity: 0.6;
}
.innerDrop {
  display: none;
}
.dropdown-specs.open .innerDrop {
  display: block;
}
.dropdown-specs.open .arrow {
  transform: rotate(180deg);
}
.innerDrop .specsAndInfoRightBar {
  display: block !important;
  margin: 20px 0;
  border: 0;
}
.innerDrop .rightBarSubList {
  margin-bottom: 10px;
}
.helpContent {
  grid-area: helpContent;
  display: grid;
  grid-template-areas:
    "read"
    "mainTopics";
  grid-template-rows: 1fr 0.5fr;
  grid-gap: 25px;
  margin: 5px 32px 10px 15px;
}
.read {
  grid-area: read;
  border-radius: 24px;
  box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4),
    -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  padding: 25px;
  display: flex;
  flex-direction: column;
}
.mainTopics {
  grid-area: mainTopics;
  border-radius: 24px;
  box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4),
    -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.specsHeading {
  margin-top: 20px !important;
}

.faq {
  padding: 0px 35px 35px 0px;
}
.helpImage {
  width: 100%;
  border-radius: 12px;
}
.helpHeading {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: -1px;
  margin: 0px 0px 15px 0px;
}
.helpParagraph,
.date {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  color: rgba(224, 224, 255, 0.6);
  word-break: break-word;
}
.readBtn {
  background-color: transparent;
  color: rgba(224, 224, 255, 0.6);
  border: none;
  float: right;
  cursor: pointer;
  padding: 7px;
  font-weight: 500;
}
.helpReadBtnDiv {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 10px;
}
.topic {
  display: grid;
  grid-template-columns: 10% 80% 10%;
  margin-top: 20px;
  align-items: center;
}

.topicName {
  color: rgba(224, 224, 255, 0.6);
  font-size: 14px;
  float: left;
  margin-right: 50px;
  cursor: pointer;
  font-weight: 500;
  line-height: 1.71;
  color: rgba(224, 224, 255, 0.6);
}
.helpRightArrow {
  float: right;
  height: 20px;
  width: 10px;
  padding-top: 5px;
}
@media screen and (max-width: 1400px) {
  .container {
    grid-template-columns: 190px 1fr !important;
    grid-template-rows: 60px 1fr !important;
    margin-top: 20px;
  }
  .helpHeading {
    font-size: 20px;
  }
  .helpContent {
    grid-gap: 15px;
  }
  .mainTopics,
  .read {
    padding: 15px;
  }
  .topic {
    grid-template-columns: 15% 75% 10%;
    align-items: center;
  }
  .help-container {
    margin-top: 20px;
  }
}
@media screen and (max-width: 480px) { 
  .mainTopics {
    grid-area: mainTopics;
    border-radius: 24px;
    box-shadow: none;
    background-image: none;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .container {
    margin-top: 0px;
  }
}
@media screen and (max-width: 480px) {
  .help-container {
    grid-area: main;
    display: grid;
    grid-template-areas:
      "helpContent"
      "faq";
    grid-template-columns: 1fr;
    color: #fff;
    margin-bottom: 90px;
  }
  .helpContent {
    grid-area: helpContent;
    display: grid;
    grid-template-areas:
      "read"
      "mainTopics";
    grid-template-rows: 1fr 0.5fr;
    grid-gap: 50px;
    margin: 32px;
  }
  .faq {
    padding: 0px;
  }
  .topicName {
    color: rgba(224, 224, 255, 0.6);
    font-size: 14px;
    float: left;
    margin-left: 10px;
    cursor: pointer;
  }
  .header-mobile {
    padding: 0 20px !important;
  }
  .container {
    grid-template-columns: auto !important;
    overflow-y: scroll;
  }
  .helpContent {
    grid-gap: 15px;
    margin: 15px;
  }
  .helpHeading {
    margin-top: 20px;
  }
  .questionDiv {
    padding: 20px;
    margin-top: 0;
  }
  .topic {
    grid-template-columns: 8% 82% 10%;
    align-items: center;
  }
  .dropdown-specs {
    display: flex;
  }

}

@media screen and (max-width: 320px) {
  .help-container {
    grid-area: main;
    display: grid;
    grid-template-areas:
      "helpContent"
      "faq";
    grid-template-columns: 1fr;
    color: #fff;
  }

  .faq {
    padding: 0px;
  }
  .topicName {
    color: rgba(224, 224, 255, 0.6);
    font-size: 14px;
    float: left;
    margin-left: 20px;
    cursor: pointer;
  }
}
.mobileMode {
  display: none;
}

.read {
  cursor: pointer;
}

.bullets {
  height: 8px;
  width: 8px;
  background-color: #4579f5;
  margin-right: 15px;
  display: inline-block;
  max-height: 8px;
  border-radius: 8px;
  min-width: 8px;
  overflow: hidden;
}

.factText {
  color: rgba(224, 224, 255, 0.6);
  font-size: 14px;
  word-break: break-word;
}

.fact {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.termsTextHelp {
  /* text-align: center; */
  padding-top: 20px;
  font-weight: 500;
}