.questionDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px 20px 30px;
  margin-top: 25px;
}
.question {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  padding-right: 10px;
}
.plusIcon {
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.minusIcon {
  height: 5px;
  width: 18px;
  cursor: pointer;
}
.expandQuestionContainer {
  border-radius: 24px;
  box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4),
    -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-image: linear-gradient(
    103deg,
    #2d2f33 1%,
    #272a2e 52%,
    #222529 100%
  );
  padding: 30px;
  margin-top: 20px;
  max-height: 0%;
  transition: 2s;
  overflow: hidden;
}
.expandQuestionContainer.show {
  border-radius: 24px;
  box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4),
    -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  padding: 30px;
  margin-top: 20px;
  max-height: 100%;
}
.expandQuestionDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
}
.expandAnswer {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: rgba(224, 224, 255, 0.6);
  white-space: pre-line;
}
.expandQuestion {
  padding-right: 10px;
}
