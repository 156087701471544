.transactions-container {
  grid-area: main;
  color: #fff;
  overflow-x: hidden;
}

.card-layout {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 30px;
  padding: 32px 32px 32px 10px;
}
.card-layout-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 30px;
  padding-left: 15px;
}
.layoutIcon {
  width: 22px;
  height: 20px;
}
.transactionContent {
  margin-left: 15px;
}
.selecetdLayoutIconDiv {
  background-color: #4579f5;
  border-radius: 12px;
  padding: 8px;
  margin-left: 15px;
  display: inline-flex;
  align-items: center;
}
.layoutIconDiv {
  border-radius: 12px;
  padding: 8px;
  margin-left: 15px;
  display: inline-flex;
  align-items: center;
}
.table-layout {
  border-radius: 24px;
  /* box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4),
    -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4); */
  background-image: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  width: 98%;
  margin-top: 40px;
  margin-bottom: 40px;
}
.transactionHeading {
  color: white;
  font-weight: 600;
  font-size: 30px;
}
.transactionsIconsDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media only screen and (max-width: 900px) {
  .card-layout {
    grid-template-columns: 100%;
    padding: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .transactionsIconsDiv {
    display: none;
  }
}
@media only screen and (max-width: 767px){
  .layoutIconDiv {
    display: none;
  }
  .card-layout-icons {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 480px) {
  .transactionHeading {
    display: none;
  }
}


@media only screen and (max-width: 1400px) {
  .transactions-container {
    margin-top: 20px;
  }
  
}