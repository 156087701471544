.masternodes-container {
  grid-area: main;
  color: #fff;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 220px 150px;
  max-width: 1024px;
  margin: auto;
}

.coming-soon {
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
}

.soon-heading {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -1.5px;
  color: #ffffff;
}

.soon-para {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 1;
  color: rgba(224, 224, 255, 0.6);
}

.node {
  border-radius: 24px;
  box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4),
    -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  margin: 0 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.visitOnMasternode {
  cursor: pointer;
  align-items: center;
}
.Markets {
  cursor: pointer;
}

.company {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: #ffffff;
  margin: 30px 20px 10px 10px;
  overflow: hidden;
  cursor: pointer;
}
.backToDashboardBtn {
  border-radius: 12px;
  /* box-shadow: 0 8px 8px -4px rgba(69, 121, 245, 0.12), 0 16px 24px 0 rgba(69, 121, 245, 0.24), 0 2px 4px -1px rgba(27, 10, 82, 0.12), 0 0 1px 0 rgba(69, 121, 245, 0.24); */
  background: rgb(254,79,0);
  background-image: linear-gradient(45deg, rgba(254,79,0,1) 0.99%, rgba(255,172,0,1) 56.16%,rgba(255,135,1,1) 98.52%);
  border: none;
  padding: 15px 20px 15px 20px;
  font-size: 18px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
}
.Markets {
  border-radius: 12px;
  width: 100%;
  height: 120px;
}
.visitMarkets {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 1px;
  color: rgba(224, 224, 255, 0.6);
}
.visitOnMasternode {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.masternodeRightArrow {
  height: 16px;
  width: 12px;
}
.activeMasternodeImageDiv {
  border-radius: 12px;
  background-color: rgba(69, 121, 245, 0.03);
  width: 48px;
  height: 48px;
}
.activeMasternodeImage {
  padding: 15px;
}
.amountOfActiveMasternodes {
  font-size: 48px;
  font-weight: 700;
  margin-top: 10px;
  cursor: pointer;
}
.activeMasternodes {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 1px;
  color: rgba(224, 224, 255, 0.6);
  margin-top: 10px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .masternodes-container {

    grid-template-columns: auto;
    grid-template-rows: auto;
    max-width: 1024px;
    margin: auto;
    width: 100%;
  }
  .masternodes-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 480px) {
  .amountOfActiveMasternodes {
    font-size: 36px;
    font-weight: 700;
    margin-top: 10px;
  }
  .masternodes-container {
    gap: 20px;
    padding: 10px 25px 25px 25px;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.5fr 0.8fr 0.5fr 0.5fr 0.3fr;
    max-width: 961px;
    margin: auto;
    width: 100%;
  }
  .backToDashboardBtn {
    width: 100%;
    margin-top: 20px;
  }
  .coming-soon {
    padding: 10px;
  }
  .node,
  .company {
    margin: 0;
  }
    .soon-heading {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -1.5px;
    color: #ffffff;
  }
  .soon-para {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: rgba(224, 224, 255, 0.6);
  }
  /* .masternodes-container {
    grid-area: main;
    color: #fff;
    padding: 150px 300px 0px 300px;
    display: grid;
    grid-template-areas:
      "commingSoon"
      "MNO" "activeNodes"
      "company1" "company2";
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 220px 150px;
  }
  .coming-soon {
    grid-area: commingSoon;
    grid-column: 1 / 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
  } */
}
