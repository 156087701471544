@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,900&display=swap');
body {
  font-family: 'Montserrat', sans-serif;
}
*, ::after, ::before {
  box-sizing: border-box;
}
img {
  max-width: 100%;
  max-height: 100%;
}
.dashboard-container {
  grid-area: main;
  display: grid;
  grid-template-areas: "content rightbar";
  grid-template-columns: 1fr 430px;
  color: #fff;
  grid-gap: 20px;
}
.content {
  grid-area: content;
  display: grid;
  grid-template-areas:
      "header header"
      "balance send"
      "help apps";
  grid-template-columns: 49% 49%;
  margin: 0 -10px;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.content::-webkit-scrollbar {
  display: none;
}
.balance {
  grid-area: balance;
  border-radius: 24px;
  box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4),
    -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  padding: 20px;
  height: 100%;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.balance-heading {
  font-size: 24px;
  font-weight: 700;
}
.balance-body {
  margin: 35px 0px 35px 0px;
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.balance-body::-webkit-scrollbar {
  display: none;
}
.app-body {
  margin: 70px 0px 30px 0px;
}
.balance-body-total {
  font-size: 14px;
  font-weight: 500;
  color: #777987;
}
.dashboard-container .header {
  padding-right: 0;
}
.app-body-total {
  font-size: 13px;
  font-weight: 600;
  color: #777987;
  margin-bottom: 10px;
  letter-spacing: 1px;
}
.balance-body-amount {
  font-size: 30px;
  font-weight: 600;
}
.app-body-amount {
  font-size: 20px;
  font-weight: 600;
}
.balance-body-unit {
  font-size: 14px;
  font-weight: 600;
}
.balance-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 25px;
  font-weight: 600;
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.balance-info::-webkit-scrollbar {
  display: none;
}

.spendable {
  display: flex;
  justify-content: space-between;
}
.spendable-type {
  color: #777987;
  display: inline-flex;
  margin-left: 4px;
}
.spendable-icon {
  margin-right: 15px;
  opacity: 0.6;
  min-width: 21px;
}
.send {
  grid-area: send;
  border-radius: 24px;
  box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4),
    -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  padding: 20px;
  height: 100%;
  position: relative;
}
.send .error {
  position: absolute;
  padding: 2px 20px;
  max-width: 400px;
}
.help {
  grid-area: help;
  display: grid;
  grid-template-areas:
    "helpp helpp"
    "growth lastPay";
  grid-gap: 20px;
  grid-template-rows: 50% 50%;
  grid-template-columns: 1fr 1fr;
  min-height: 93%;
}
.helpp {
  grid-area: helpp;
  border-radius: 24px;
  box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4),
    -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  padding: 20px;
}
.helpTitle {
  color: #777987;
  font-size: 13px;
}
.whyDelay {
  font-size: 16px;
  letter-spacing: 1;
}
.growth {
  grid-area: growth;
  border-radius: 24px;
  /* box-shadow: 0 8px 8px -4px rgba(69, 121, 245, 0.12),
    0 16px 24px 0 rgba(69, 121, 245, 0.24),
    0 2px 4px -1px rgba(27, 10, 82, 0.12), 0 0 1px 0 rgba(69, 121, 245, 0.24); */
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  /* height: 70%;
  padding: 30px 0px 0px 0px; */
}
.growthDiv {
  display: flex;
  justify-content: space-between;
  
}
.growthPercent {
  font-size: 18px;
  font-weight: 600;
  margin-right: 5px;
  text-align: left;
}
.batteryIcon {
  float: right;
  max-width: 18px;
}
.rectangleIcon {
  width: 97%;
  float: right;
  height: 70px;
}
.lastChangeAmount {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  word-break: break-word;
  cursor: pointer;
}
.lastPayIcon {
  max-width: 20px;
}
.lastPay {
  grid-area: lastPay;
  border-radius: 24px;
  box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4),
    -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
}
.lastPayAmount {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  word-break: break-word;
}
.lastPayTitle {
  float: right;
  font-size: 13px;
  color: #777987;
  font-weight: 700;
  letter-spacing: 1;
  text-align: right;
}
.lastChangeTitle {
  float: left;
  font-size: 13px;
  color: #777987;
  font-weight: 700;
  letter-spacing: 1;
  text-align: right;
}
.apps {
  grid-area: apps;
  border-radius: 24px;
  box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4), -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  padding: 20px;
  min-height: 98.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.right-sidebar {
  grid-area: rightbar;
  padding: 20px;
  border-radius: 0 0 0 40px;
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  width: 430px;
  overflow-y: auto;
  overflow-x: hidden;
}
.right-sidebar::before {
  content: "";
  height: 100px;
  position: fixed;
  bottom: 0px;
  right: 0;
  width: 430px;
  /* background-image: linear-gradient(to bottom, rgba(39, 42, 46, 0), #272a2e); */
  border-radius: 0 0 40px 40px;
  pointer-events: none;
}
.right-sidebar .transaction {
  margin: 25px 0;
}
.scroll-trans {
  height: 100%;
  overflow: visible;
}

.appIconsDiv {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
  margin-top: 40px;
}
.appIcon {
  border-radius: 15px;
  width: 93%;
  cursor: pointer;
}
.appIcon img {
  width: 100%;
  text-align: center;
}
.helpDiv {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 24px;
}
.helpIcon {
  float: right;
  width: 20px;
}
.getKnowBtn {
  float: right;
  border: none;
  background-color: transparent;
  color: white;
  width: 62%;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 15px;
  background-color: rgba(224, 224, 255, 0.02);
  padding: 15px 10px;
  letter-spacing: 1px;
}
.getKnowBtn:focus,
.viewAllBtn:focus,
.clearAllBtn:focus,
.sendBtn:focus,
.sendAndReceiveBtn:focus,
.codeInputField:focus {
  outline: 0;
}
.prcy {
  font-size: 14px;
  font-weight: 700;
}
.transactionTitleDiv {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}
.transactionTitle {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
}
.viewAllBtn {
  float: right;
  font-size: 14px;
  border-radius: 15px;
  padding: 10px 15px;
  border: none;
  color: white;
  cursor: pointer;
  background-color: rgba(224, 224, 255, 0.02);
  display: inline-flex;
  align-items: center;
}
.rightArrowIcon {
  width: 8px;
  opacity: 0.6;
  margin-left: 15px;
}
.codeInputField {
  background-color: transparent;
  width: 100%;
  height: 48px;
  border: none;
  outline: 0;
  color: white;
  font-size: 16px;
}
.codeInputFieldDiv {
  margin-top: 20px;
  border-radius: 12px;
  border: none;
  background-color: rgba(224, 224, 255, 0.02);
  color: white;
  padding: 0px 20px 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.btnsDiv {
  border-radius: 12px;
  box-shadow: inset 8px 8px 40px 0 rgba(9, 14, 20, 0.4),
    inset -4px -4px 8px 0 rgba(224, 224, 255, 0.04),
    inset 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-image: linear-gradient(to bottom, #090e14, #222529 58%, #2d2f33);
  height: 36px;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}
.sendAndReceiveBtn {
  border-radius: 10px;
  box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4),
    -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4),
    inset 0 -2px 1px 0 rgba(9, 14, 20, 0.8);
  background-image: linear-gradient(to bottom, #383a3d, #2d2f33);
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  outline: 0;
}
.sendAndReceiveBtnInActive {
  border-radius: 12px;
  box-shadow: inset 8px 8px 40px 0 rgba(9, 14, 20, 0.4),
    inset -4px -4px 8px 0 rgba(224, 224, 255, 0.04),
    inset 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-image: linear-gradient(to bottom, #090e14, #222529 58%, #2d2f33);
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  outline: 0;
}
.sendBtn {
  border-radius: 12px;
  /* box-shadow: 0 8px 8px -4px rgba(69, 121, 245, 0.12),
    0 16px 24px 0 rgba(69, 121, 245, 0.24),
    0 2px 4px -1px rgba(27, 10, 82, 0.12), 0 0 1px 0 rgba(69, 121, 245, 0.24); */
  background: rgb(254,79,0);
  background-image: linear-gradient(45deg, rgba(254,79,0,1) 0.99%, rgba(255,172,0,1) 56.16%,rgba(255,135,1,1) 98.52%);
  border: none;
  padding: 15px 20px 15px 20px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  font-weight: 700;
  float: right;
}
.requestBtn {
  border-radius: 12px;
  /* box-shadow: 0 8px 8px -4px rgba(69, 121, 245, 0.12),
    0 16px 24px 0 rgba(69, 121, 245, 0.24),
    0 2px 4px -1px rgba(27, 10, 82, 0.12), 0 0 1px 0 rgba(69, 121, 245, 0.24); */
  background-image: linear-gradient(117deg, #3a7df1, #eaf545 53%, #066 100%);
  border: none;
  padding: 15px 25px 15px 25px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  font-weight: 700;
  float: right;
}
.clearAllBtn {
  border: none;
  background-color: transparent;
  color: #3a7df1;
  font-weight: 700;
  font-size: 16px;
  float: right;
  padding: 15px 0;
  cursor: pointer;
  margin-right: 20px;
}
.clearAllBtnsDiv {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
}
.sendArrowIcon {
  margin-left: 15px;
  height: 15px;
  width: 20px;
}
.scanIcon {
  height: 24px;
  width: 24px;
}
.loader-container {
  grid-area: main;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75%;
}
.p-10 {
  padding: 10px;
  flex: 1;
}
.growth,
.lastPay {
  min-height: 128px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
}
.lastPay {
  padding-bottom: 20px;
}
.lastPay .growthDiv,
.growth .growthDiv {
  padding: 15px;
  position: relative;
  top: 12px;
}

.dashboard-container {
  grid-gap: 4px;
}
.whyDelay {
  font-size: 14px;
}

.sendBtnsDiv {
  margin-bottom: 30px;
}

.buttonLink {
  color: #4579f5;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 14px;
  float: right;
}

@media (max-width: 480px) {
  .clearAllBtnsDiv  {
    position: static;
    margin-top: 20px;
    justify-content: flex-end;
  }

  .balance-body-amount {
    font-size: 24px;
  }
  
  .balance {
    grid-area: balance;
    border-radius: 24px;
    box-shadow: none;
    background-image: none;
    padding: 20px;
    height: 100%;
    display: flex;
      flex-direction: column;
      justify-content: space-between;
  }
  .balance-body {
    margin: 10px 0px 30px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .balanceQRGenerate {
    border-radius: 10px;
    /* box-shadow: 0 8px 8px -4px rgba(69, 121, 245, 0.12), 0 16px 24px 0 rgba(69, 121, 245, 0.24), 0 2px 4px -1px rgba(27, 10, 82, 0.12), 0 0 1px 0 rgba(69, 121, 245, 0.24); */
    background: rgb(254,79,0);
    background-image: linear-gradient(45deg, rgba(254,79,0,1) 0.99%, rgba(255,172,0,1) 56.16%,rgba(255,135,1,1) 98.52%);
    border: none;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    margin-left: 10px;
  }
  .balanceQRGenerateIcon {
    height: 15px;
    width: 15px;
    margin: 12px;
  }
}
@media (max-width: 1400px) {
  
  .dashboard-container {
    grid-template-columns: 1fr 300px !important;
  }
  .d-container {
    grid-template-columns: 200px 1fr !important;
  }
  .right-sidebar {
    width: 300px;
    margin-right: 5px;
  }
  .right-sidebar::before {
    content: "";
    width: 300px;
  }
  
  .sidebar {
    max-width: 180px;
    left: 5px;
  }
  .selectedItemDiv, 
  .itemDiv,
  .logoutDiv {
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .menuItemIcon {
    width: 12px;
    height: auto;
    margin-right: 4px;
  }
  
  .transactionTitleDiv {
    grid-template-columns: auto;
  }
  
  .viewAllBtn {
    float: left;
    margin-top: 10px;
  }

  .scroll-trans .transactionSentIconDiv {
    display: none;
  }

  .scroll-trans .transactionReceivedIconDiv {
    display: none;
  }

  .scroll-trans .transactionContent {
    margin-left: 0px;
  }

}
@media screen and (max-width: 1200px){
  .dashboard-container {
    grid-template-columns: auto !important;
  }
  
}
.mobileMode {
  display:none;
}
.balanceIcons {
  height: auto;
  width: 16px;
  opacity: 0.6;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media screen and (max-width: 480px) {
  .helpDiv {
    grid-template-columns: 50% 50%;
  }
  .getKnowBtn {
    width: 45%;
    margin-top: 10px;
    padding: 10px;
  }
  .d-container {
    overflow-y: scroll;
  }
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.appIconss {
  height: 48px;
  width: 48px;
}
.getKnowBtnIcon {
  height: 15px;
  width: 15px;
}