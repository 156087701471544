.specsAndInfo-container {
  grid-area: main;
  display: grid;
  grid-template-areas: "content rightbar";
  grid-template-columns: 1fr;
  color: #fff;
  overflow-y: scroll;
}
.specsAndInfoContent {
  grid-area: content;
  display: grid;
  grid-template-areas:
    "InfoContent"
    "footer";
  grid-template-rows: auto 50px;
  grid-template-columns: 100%;
  margin: 0px 32px 32px 25px;
}
.specsAndInfoRightBar {
  width: 360px;
  height: 210px;
  margin-top: 40px;
  border-left: 2px solid #2c2e35;
  position: sticky;
  top: 20px;
}
.specsAndInfoContentDiv {
  grid-area: InfoContent;
}
.specsAndInfoHeading {
  font-size: 36px;
  font-weight: 700;
  color: white;
}
.specsAndInfoPara {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: normal;
  color: rgba(224, 224, 255, 0.6);
  margin-top: 50px;
  white-space: pre-line;
}
.specsAndInfoImage {
  width: 100%;
  max-width: 650px;
  border-radius: 24px;
  margin-top: 50px;
}
.captionPlaceholder {
  text-align: left;
  font-size: 14px;
  color: rgba(224, 224, 255, 0.6);
  margin-top: 10px;
}
.shamefullMomentsHeading {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1;
  margin-top: 50px;
}
.shamefullMomentsPoints {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 1;
  color: rgba(224, 224, 255, 0.6);
  margin-top: 30px;
  padding-left: 10px;
}
.links {
  color: #4579f5;
  cursor: pointer;
}
.systemDiv,
.summaryDiv {
  margin: 40px 0px 40px 0px;
  padding: 40px;
  border-left: 4px solid #4579f5;
  border-radius: 2px;
}
.systemHeading {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1;
  margin-bottom: 20px;
}
.systemContent {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: normal;
  color: rgba(224, 224, 255, 0.6);
  white-space: pre-line;
}
.summaryPointDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
}
.summaryIcon {
  height: auto;
  width: 18px;
  margin-right: 15px;
  color: rgba(224, 224, 255, 0.6);
}
.bullets {
  height: 8px;
  width: 8px;
  background-color: #4579f5;
  margin-right: 15px;
  display: inline-block;
  max-height: 8px;
  border-radius: 8px;
  min-width: 8px;
  overflow: hidden;
}
.specsAndInfoFooter {
  grid-area: footer;
}
.rightBarListDiv {
  padding-left: 20px;
  margin-bottom: 10px;
}
.selectedRightBarListDiv {
  padding-left: 20px;
  margin-bottom: 10px;
  border-left: 2px solid #4579f5;
}
.rightBarList {
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  line-height: 2;
}
.rightBarSubList {
  font-size: 14px;
  color: rgba(224, 224, 255, 0.6);
  line-height: 2;
}
.sublist {
  margin-left: 50px;
}

@media screen and (max-width: 1440px) {
  .specsAndInfoContentDiv {
    padding: 0;
  }
}
@media screen and (max-width: 768px) {
  .specsAndInfo-container {
    display: block;
  }
  .specsAndInfoRightBar {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .specsAndInfoContentDiv {
    padding-top: 10px;
  }
  .specsAndInfoHeading {
    display: none;
  }
  
  .specsAndInfoHeading {
    font-size: 30px;
    margin-bottom: 25px;
  }
  .specsAndInfoPara{
    margin-top: 0;
  }
  .specsAndInfo-container {
    margin-bottom: 90px;
  }
  .socialMediaIcon {
    padding: 0 !important;
    width: 16px;
  }
  .socialMediaDiv {
    height: auto !important;
    width: auto !important;
  }
  .footerContainer {
    align-items: center;
  }
  .specsAndInfo2ImageDiv,
  .specsAndInfo1ImageDiv {
    margin: 0 -50px;
  }
  .specsAndInfoImage {
    width: auto;
    border-radius: 0px;
    margin: 20px 0 !important;
  }
  .specsAndInfo-container {
    overflow-x: hidden;
  }
}
.dropCap {
  color: white;
  float: left;
  font-size: 60px;
  line-height: 56px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;

}