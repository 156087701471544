.loginContainer {
  display: grid;
  grid-template-columns: 450px auto;
  height: 100%;
}
.loginContainer > div:first-child {
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  width: 100%;
}
.sideImage {
  width: 100%;
  min-width: 520px;
  max-height: inherit;
  height: 100%;
  object-fit: cover;
}
.loginLeftPane {
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  height: 100%;
  display: grid;
  grid-template-rows: 25% 50% 25%;
  grid-template-columns: 25% 50% 25%;
  height: 100%;
}
.loginDiv {
  grid-column-start: 2;
  grid-row-start: 2;
}
.loginHeading {
  font-size: 36px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -1px;
  color: #ffffff;
  margin-bottom: 35px;
}
.enterMessage {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;
  color: rgba(224, 224, 255, 0.4);
  padding-left: 15px;
}
.loginInputField {
  height: 120px;
  border-radius: 12px;
  background-color: rgba(224, 224, 255, 0.02);
  border: none;
  width: 100%;
  padding: 10px;
  color: white;
  resize: none;
  font-size: 16px;
  overflow: hidden;
  outline: 0;
  text-transform: lowercase;
}
.bitCoinCheckDiv {
  display: grid;
  grid-template-columns: 25px auto;
  margin-top: 20px;
  align-items: center;
}
.bitCoinCheckBox {
  background-color: #3a7df1;
  margin: 0px;
  cursor: pointer;
}
.bitCoinCheckMessage {
  color: rgba(224, 224, 255, 0.6);
  margin: 0px;
  font-size: 14px;
}
.termsText {
  color: rgba(224, 224, 255, 0.6);
}
.loginBtnDiv {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: 50% 50%;
  grid-gap: 0px;
  margin-top: 20px;
}
.dntHaveWalletDiv {
  grid-column-start: 1;
  grid-row-start: 1;
  color: rgba(224, 224, 255, 0.6);
  font-size: 14px;
}
.dntHaveWalletDiv p {
  margin-bottom: 5px;
}
.dntHaveWalletLinkDiv {
  grid-column-start: 1;
  grid-row-start: 2;
}
.dntHaveWalletLink {
  color: #4579f5;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 14px;
  padding-left: 0px;
}
.loginBtn {
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  margin-top: 10px;
}
.btn {
  width: 95px;
  height: 48px;
  border-radius: 12px;
  /* box-shadow: 0 8px 8px -4px rgba(69, 121, 245, 0.12),
    0 16px 24px 0 rgba(69, 121, 245, 0.24),
    0 2px 4px -1px rgba(27, 10, 82, 0.12), 0 0 1px 0 rgba(69, 121, 245, 0.24); */
  background: rgb(254,79,0);
  background-image: linear-gradient(45deg, rgba(254,79,0,1) 0.99%, rgba(255,172,0,1) 56.16%,rgba(255,135,1,1) 98.52%);
  border: none;
  float: right;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 16px;
}
.btn:focus,
.loginInputField:focus,
.dntHaveWalletLink:focus {
  outline: 0;
}
.errors {
  color: #ff0037;
  font-size: 14px;
  padding-left: 3px;
  padding: 5px 20px;
}
.error-border {
  border: 2px solid #ff00aa !important;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.fadeMe {
  background-color: rgba(0,0,0,0.5);
  width:      100%;
  height:     100%; 
  z-index:    10;
  top:        0; 
  left:       0; 
  position:   fixed; 
}

@media screen and (max-width: 768px) {
  .loginContainer {
    grid-template-columns: auto;
  }
  .loginContainer>div:first-child {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .loginContainer {
    display: grid;
    grid-template-columns: auto;
    height: 100%;
  }
  .loginLeftPane {
    display: flex;
    justify-content: center;
  }
  .loginDiv {
    width: 100%;
    padding: 15px;
  }
  .loginBtnDiv {
    position: absolute;
    bottom: 0;
    width: 91%;
  }

}

@media screen and (max-width: 480px) and (max-height: 550px) {
  .loginBtnDiv {
    display: grid;
    position: relative;
    grid-template-columns: 70% 30%;
    grid-template-rows: 50% 50%;
    grid-gap: 0px;
    margin-top: 20px;
  }

}

@media screen and (max-width: 320px) {
  .loginContainer {
    display: grid;
    grid-template-columns: auto;
    height: 100%;
  }
}
