.loaderDiv {
  text-align: center;
}
.loaderHeading {
  font-size: 36px;
  color: white;
  margin-top: 20px;
  align-content: center;
}
.loaderMessage {
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: 1;
  color: rgba(224, 224, 255, 0.6);
  margin: 25px auto;
  width: 70%;
  text-align: center;
}

@media (max-width: 480px) {
  .loaderMessage {
    width: 90%;
  }
}
