.header {
  grid-area: header;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* margin: 20px 0 10px 0; */
  color: #fff;
  padding-right: 25px;
}
.info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.infoImageDiv {
  border-radius: 10px;
  box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4),
    -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-color: rgb(1,40,40);
  background: linear-gradient(45deg, rgba(1,40,40,1) 0.99%, rgba(0,102,102,1) 56.16%,rgba(0,51,51,1) 98.52%);
  min-width: 20px;
}
.masterAccountDiv {
  padding: 20px 10px;
  margin-right: 8px;
}
.masterAccountLabel {
  color: #777987;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 4px;
  display: inline-block;
}
.infoImageDiv,
.copyIcon {
  min-width: 16px;
  
}
.infoImageDiv {
  padding: 8px 11px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.infoImageDiv img {
  opacity: 0.5;
  max-width: 24px;
}
.copyIcon img {
  opacity: 0.5;
  max-width: 20px;
}
.copyIcon img {
  cursor: pointer;
}
.qrGenerateBtn {
  border-radius: 12px;
  /* box-shadow: 0 8px 8px -4px rgba(69, 121, 245, 0.12), 0 16px 24px 0 rgba(69, 121, 245, 0.24), 0 2px 4px -1px rgba(27, 10, 82, 0.12), 0 0 1px 0 rgba(69, 121, 245, 0.24); */
  background: rgb(254,79,0);
  background-image: linear-gradient(45deg, rgba(254,79,0,1) 0.99%, rgba(255,172,0,1) 56.16%,rgba(255,135,1,1) 98.52%);
  border: none;
  padding: 15px 20px 15px 20px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}
.qrGenerateIcon {
  padding-right: 15px;
  height: 15px;
  width: 32px;
}
.qrGenerateBtn:focus {
  outline: 0;
}
.text-right {
  text-align: right;
}
.masterKeyInput {
  background-color: transparent;
  border: none;
  outline: 0;
  height: 100%;
  color: white;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
}