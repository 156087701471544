.transaction {
  width: 100%;
  height: 115px;
  border-radius: 24px;
  box-shadow: 8px 8px 24px 0 rgba(9, 14, 20, 0.4),
    -4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 14, 20, 0.4);
  background-image: linear-gradient(107deg, #2d2f33 1%, #272a2e 52%, #222529);
  margin: 0;
}
.transactionInnerDiv {
  display: flex;
  padding:  25px 20px 8px 20px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
}
.transactionTime {
  float: right;
  margin-right: 20px;
}
.transactionSentIconDiv {
  background-color: #eaf545;
  border-radius: 12px;
  align-items: center;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}
.transactionReceivedIconDiv {
  background-color: #3a7df1;
  border-radius: 12px;
  align-items: center;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}
.transactionContent {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  width: 100%;
  overflow: hidden;
}
.transactionStatus{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.transactionIds {
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  margin-left: 10px;
  text-align: right;
  overflow-x: scroll;
  width: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.transactionIds::-webkit-scrollbar {
  display: none;
}
.txId,
.amount {
  font-size: 18px;
  font-weight: 600;
}

.txId a {
  color: white !important;
  text-decoration: none !important;
}
.amount {
  white-space: nowrap;
}
.amountInDollars {
  font-size: 13px;
  color: #777987;
  font-weight: 600;
  white-space: nowrap;
}
.sendType {
  font-size: 13px;
  color: #00feae;
  text-transform: uppercase;
  font-weight: 600;
}
.receiveType {
  font-size: 13px;
  color: #9c4feb;
  text-transform: uppercase;
  font-weight: 600;
}
.sentIcon, .receiveIcon {
  margin: 16.5px;
  height: 15px;
  width: 15px;
}
